<template>
  <main id="main">
    <h4 class="text-center">ASESOR FINANCIERO</h4>
    <b-container class="mt-3">
      <b-form class="container-form" @reset="onReset" v-if="show">
        <b-row>
          <b-col class="col-12 col-sm-6 col-md-6 col-lg-4">
            <b-form-group id="input-group-1" label="Programa:" label-for="input-1">
              <b-form-select id="input-1" v-model="form.carrera" :options="form.carreras" @input="onChage" required>
              </b-form-select>
              <div class="mt-3">
                Costo de la matricula: <strong>{{ form.carrera }}</strong> pesos
              </div>
            </b-form-group>
          </b-col>

          <b-col class="col-12 col-sm-6 col-md-6 col-lg-4">
            <b-form-group id="input-group-2" label="Estrato:" label-for="input-2">
              <b-form-select id="input-2" v-model="form.estrato" :options="form.estratos" @input="onChage" required>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col class="col-12 col-sm-6 col-md-6 col-lg-4">
            <b-form-group id="input-group-3" label="Edad:" label-for="input-3">
              <b-form-select id="input-3" v-model="form.edad" :options="form.edades" @input="onChage"
                required></b-form-select>
            </b-form-group>
          </b-col>

          <b-col class="col-12 col-sm-6 col-md-6 col-lg-4">
            <b-form-group id="input-group-4" label="Sisben IV:" label-for="input-4">
              <b-form-select id="input-4" v-model="form.sisben" :options="form.optionsSisben" @input="onChage"
                required></b-form-select>
            </b-form-group>
          </b-col>

          <b-col class="col-12 col-sm-6 col-md-6 col-lg-4">
            <b-form-group id="input-group-5" label="Genero:" label-for="input-5"><b-form-select id="input-5"
                v-model="form.genero" :options="form.generos" @input="onChage" required>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="col-12 col-sm-6 col-md-6 col-lg-4">
            <b-form-group id="input-group-6" label="Año de grado:" label-for="input-6">
              <b-form-select id="input-6" v-model="form.grado" :options="form.optionsGrado" @input="onChage" required>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group id="input-group-7" label="Semestre a cursar:" label-for="input-7">
              <b-form-select id="input-7" v-model="form.semestre" :options="form.semestres" @input="onChage" required>
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col class="col-12 col-sm-6 col-md-6 col-lg-4">
            <b-form-group id="input-group-8" label="Caracteristicas especiales de Becas:" label-for="input-8">
              <b-form-select id="input-8" v-model="form.beca" :options="form.becas" @input="onChage">
              </b-form-select>
            </b-form-group>
          </b-col>

          <b-col class="col-12 col-sm-6 col-md-6 col-lg-4">
            <b-form-group id="input-group-9" label="Puntaje del ICFES:" label-for="range-1">
              <b-form-input id="range-1" v-model="form.puntajeIcfes" type="range" min="0" max="500"
                @input="onChage"></b-form-input>
              <div class="mt-2">Value: {{ form.puntajeIcfes }}</div>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="col-12 col-sm-6 col-md-6 col-lg-4">
            <b-form-group id="input-group-10" label="Capacidad de pago mensual:" label-for="range-2">
              <b-form-input id="range-2" v-model="form.pagoMensual" type="range" min="100000" max="10000000" step="1000"
                @input="onChage"></b-form-input>
              <div class="mt-2">
                Selected:
                <strong>{{ form.pagoMensual }} Pesos Colombianos</strong>
              </div>
            </b-form-group></b-col>

          <b-col v-if="form.carrera != null">
            <b-form-group id="input-group-11" label="% Valor a financiar:" label-for="range-3">
              <b-form-input id="range-3" v-model="form.valorFinanciar" type="range" min="0" max="100" step="5"
                @input="onChage"></b-form-input>
              <div class="mt-2">
                <strong>{{ form.valorFinanciar }} % es igual a :
                  {{ Math.trunc((form.carrera * form.valorFinanciar) / 100) }}
                  pesos</strong>
              </div>
            </b-form-group></b-col>

          <b-col v-if="form.semestre > 1">
            <b-form-group id="input-group-12" label="Promedio de notas:" label-for="range-4">
              <b-form-input id="range-4" v-model="form.promedioNota" type="range" min="0" max="5" step="0.01"
                @input="onChage"></b-form-input>
              <div class="mt-2">
                Selected: <strong>{{ form.promedioNota }} </strong>
              </div>
            </b-form-group></b-col>
        </b-row>
        <b-button type="reset" variant="danger">Reiniciar</b-button>
      </b-form>

      <table class="table table-bordered  table-dark">
        <thead>
          <tr>
            <!-- Iterar sobre los encabezados -->
            <th v-for="header in headers" :key="header.key">{{ header.key }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id">
            <!-- Iterar sobre los datos -->
            <td><a :href=item.link target="_blank" rel="noopener noreferrer"> <u>{{ item.Linea }}</u></a></td>
            <td>{{ item.Decision }}</td>
          </tr>
        </tbody>
      </table>
    </b-container>
  </main>
</template>
<script>
import * as data from "./data"

export default {
  name: "AsesorFinanciero",
  data() {
    return {
      form: {
        carreras: data.CARRERAS_UNINORTE,
        carrera: null,
        estratos: data.ESTRATOS,
        estrato: null,
        edad: null,
        edades: data.OPCIONES_EDAD,
        sisben: null,
        optionsSisben: data.OPCIONES_SISBEN,
        genero: null,
        generos: data.OPCIONES_GENERO,
        grado: null,
        optionsGrado: data.GRADUATION_DATE,
        semestre: null,
        semestres: data.SEMESTRES,
        beca: null,
        becas: data.BECAS,
        puntajeIcfes: 0,
        pagoMensual: 500000,
        valorFinanciar: 0,
        promedioNota: 3.4
      },
      show: true,

      headers: [
        {
          key: "Linea",
          sortable: true
        },
        {
          key: "Decision",
          sortable: true
        }
      ],
      items: data.DESICIONES_UNINORTE,
      desserts: data.DESICIONES_UNINORTE
    }
  },

  components: {},

  methods: {
    onChage(event) {
      this.items = []
      if (
        this.form.estrato > 0 &&
        this.form.estrato < 4 &&
        this.form.puntajeIcfes >= 300 &&
        this.form.promedioNota >= 3.4
      ) {
        this.items.push(this.desserts[0])
      }
      if (
        this.form.estrato > 0 &&
        this.form.estrato < 4 &&
        this.form.puntajeIcfes >= 270 &&
        this.form.promedioNota >= 3.4
      ) {
        this.items.push(this.desserts[1])
      }
      if (
        this.form.estrato > 0 &&
        this.form.estrato < 7 &&
        this.form.puntajeIcfes >= 260 &&
        this.form.promedioNota >= 3.4 &&
        this.form.pagoMensual > 500000 &&
        this.form.valorFinanciar === 100
      ) {
        this.items.push(this.desserts[2])
      }
      if (
        this.form.estrato > 0 &&
        this.form.estrato < 7 &&
        this.form.puntajeIcfes >= 240 &&
        this.form.promedioNota >= 3.4
      ) {
        this.items.push(this.desserts[3])
      }
      if (
        this.form.estrato > 0 &&
        this.form.estrato < 7 &&
        this.form.puntajeIcfes >= 240 &&
        this.form.promedioNota >= 3.4
      ) {
        this.items.push(this.desserts[4])
      }
      if (this.form.semestre === 1) {
        this.items.push(this.desserts[5])
        this.items.push(this.desserts[7])
      }
      if (this.form.promedioNota > 3.25 && this.form.semestre > 1) {
        this.items.push(this.desserts[6])
        this.items.push(this.desserts[8])
      }
      // Todos menos medicina y odontología
      if (
        this.form.grado >= 2020 &&
        this.form.edad <= 20 &&
        this.form.semestre === 1
      ) {
        this.items.push(this.desserts[9])
        this.items.push(this.desserts[10])
        this.items.push(this.desserts[11])
      }
      if (
        this.form.grado >= 2020 &&
        this.form.edad <= 20 &&
        this.form.beca === 0 &&
        this.form.semestre === 1
      ) {
        this.items.push(this.desserts[12])
      }
      // Lic y pedagogia infantil
      if (
        this.form.grado >= 2020 &&
        this.form.edad <= 25 &&
        this.form.edad >= 16 &&
        this.form.semestre === 1
      ) {
        this.items.push(this.desserts[13])
      }
      // Solo carreras como economia o matematicas. revisar diapositiva
      if (
        this.form.grado >= 2020 &&
        this.form.genero === "Femenino" &&
        this.form.edad <= 25 &&
        this.form.beca <= 2 &&
        this.form.beca != null &&
        this.form.semestre === 1
      ) {
        this.items.push(this.desserts[14])
      }
      // Menos medicina y odontologia
      if (
        this.form.grado >= 2020 &&
        this.form.edad <= 21 &&
        this.form.semestre === 1
      ) {
        this.items.push(this.desserts[15])
      }
    },
    onReset(event) {
      // Reset our form values
      this.form.carrera = null
      this.form.estrato = null
      this.form.edad = null
      this.form.sisben = null
      this.form.genero = null
      this.form.grado = null
      this.form.semestre = null
      this.form.beca = null
      this.form.puntajeIcfes = 0
      this.form.pagoMensual = 500000
      this.form.valorFinanciar = 0
      this.form.promedioNota = 3.4
      this.items = this.desserts

      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  }
}
</script>
<style>
.container-form {
  background: var(--iq-bg1) !important;
  padding: 2rem;
  border-radius: 1rem;
}
</style>
