export const CARRERAS_UNINORTE = [
  { text: "Administración de Empresas", value: 10835100 },
  { text: "Psicología", value: 8057300 },
  { text: "Derecho", value: 9030600 },
  { text: "Ingeniería Industrial", value: 11195800 },
  { text: "Ingeniería Eléctrica", value: 9132500 },
  { text: "Ingeniería Electrónica", value: 10409100 },
  { text: "Ingeniería de Sistemas", value: 9132500 },
  { text: "Ingeniería Mecánica", value: 9132500 },
  { text: "Ingeniería Civil", value: 9132500 },
  { text: "Medicina", value: 19022300 },
  { text: "Comunicación Social", value: 8057300 },
  { text: "Licenciatura en Pedagogía Infantil", value: 5855200 },
  { text: "Enfermería", value: 5536500 },
  { text: "Economía", value: 8445500 },
  { text: "Relaciones Internacionales", value: 8526400 },
  { text: "Diseño Industrial", value: 11074600 },
  { text: "Negocios Internacionales", value: 12057900 },
  { text: "Diseño Gráfico", value: 8976300 },
  { text: "Matemáticas", value: 5485700 },
  { text: "Ciencia Política y Gobierno", value: 8861000 },
  { text: "Música", value: 9060000 },
  { text: "Arquitectura", value: 11180700 },
  { text: "Contaduría Pública", value: 5345700 },
  { text: "Filosofía y Humanidades", value: 4654100 },
  { text: "Odontología", value: 13021000 },
  { text: "Geología", value: 8968700 },
  { text: "Licenciatura en Filosofía", value: 5388200 },
  { text: "Licenciatura en Matemática", value: 5388200 },
  { text: "Lenguas Modernas y Cultura", value: 8526400 },
  { text: "Ciencia de Datos", value: 9132500 }
]

export const ESTRATOS = [
  { value: null, text: "Selecciona una opción" },
  { value: 1, text: "Estrato 1" },
  { value: 2, text: "Estrato 2" },
  { value: 3, text: "Estrato 3" },
  { value: 4, text: "Estrato 4" },
  { value: 5, text: "Estrato 5" },
  { value: 6, text: "Estrato 6" }
]

export const OPCIONES_EDAD = [
  { value: null, text: "Seleccione una opción" },
  { value: "menos de 16", text: "Menos de 16 años" },
  { value: 16, text: "16" },
  { value: 17, text: "17" },
  { value: 18, text: "18" },
  { value: 19, text: "19" },
  { value: 20, text: "20" },
  { value: 21, text: "21" },
  { value: 22, text: "22" },
  { value: 23, text: "23" },
  { value: 24, text: "24" },
  { value: 25, text: "25" },
  { value: "mas de 25", text: "Más de 25 años" }
]

export const OPCIONES_SISBEN = [
  { value: null, text: "Seleccione una opcion" },
  { value: "A", text: "A" },
  { value: "B", text: "B" },
  { value: "C", text: "C" },
  { value: "D", text: "D" }
]

export const OPCIONES_GENERO = [
  { value: null, text: "Seleccione una opcion" },
  { value: "Masculino", text: "Masculino" },
  { value: "Femenino", text: "Femenino" },
  { value: "No Binario", text: "No Binario" },
  { value: "Otro", text: "Otro" }
]

export const GRADUATION_DATE = [
  { value: null, text: "Seleccione una opcion" },
  { value: "antes del 2015", text: "antes del 2015" },
  { value: 2015, text: "2015" },
  { value: 2016, text: "2016" },
  { value: 2017, text: "2017" },
  { value: 2018, text: "2018" },
  { value: 2019, text: "2019" },
  { value: 2020, text: "2020" },
  { value: 2021, text: "2021" },
  { value: 2022, text: "2022" },
  { value: 2023, text: "2023" }
]

export const SEMESTRES = [
  { value: null, text: "Seleccione una opcion" },
  { value: 1, text: "1" },
  { value: 2, text: "2" },
  { value: 3, text: "3" },
  { value: 4, text: "4" },
  { value: 5, text: "5" },
  { value: 6, text: "6" },
  { value: 7, text: "7" },
  { value: 8, text: "8" },
  { value: 9, text: "9" },
  { value: 10, text: "10" }
]

export const BECAS = [
  { value: null, text: "Seleccione una opcion" },
  {
    value: 0,
    text: "Deportista avalado por federadas y/o gubernamentales del deporte"
  },
  { value: 1, text: "Méritos Académicos" },
  { value: 2, text: "Liderazgo Estudiantil" },
  { value: 3, text: "Otro" }
]

export const DESICIONES_UNINORTE = [
  { isActive: true, Decision: "Icetex", Linea: "0%", link: 'https://web.icetex.gov.co/' },
  { isActive: true, Decision: "Icetex", Linea: "25%", link: 'https://web.icetex.gov.co/' },
  { isActive: true, Decision: "Icetex", Linea: "30%", link: 'https://web.icetex.gov.co/' },
  { isActive: true, Decision: "Icetex", Linea: "40% - 60%", link: 'https://web.icetex.gov.co/' },
  { isActive: true, Decision: "Icetex", Linea: "100%", link: 'https://web.icetex.gov.co/' },
  {
    isActive: true,
    Decision: "UN",
    Linea: "Credito un CP nuevo ingreso",
    link: ''
  },
  { isActive: true, Decision: "UN", Linea: "Credito un CP antiguo", link: 'https://www.uninorte.edu.co/web/apoyo-financiero' },
  {
    isActive: true,
    Decision: "UN",
    Linea: "Credito un LP nuevo ingreso",
    link: 'https://www.uninorte.edu.co/web/apoyo-financiero'
  },
  { isActive: true, Decision: "UN", Linea: "Credito un CP antiguo", link: 'https://www.uninorte.edu.co/web/apoyo-financiero/becas' },
  { isActive: true, Decision: "Beca", Linea: "Beca Mejor Icfes", link: 'https://www.uninorte.edu.co/web/apoyo-financiero/becas' },
  { isActive: true, Decision: "Beca", Linea: "Alejandro Obregón", link: 'https://www.uninorte.edu.co/web/apoyo-financiero/becas' },
  { isActive: true, Decision: "Beca", Linea: "Orgullo Caribe", link: 'https://www.uninorte.edu.co/web/apoyo-financiero/becas' },
  {
    isActive: true,
    Decision: "Beca",
    Linea: "Beca Excelencia Deportiva",
    link: 'https://www.uninorte.edu.co/web/apoyo-financiero/becas'
  },
  {
    isActive: true,
    Decision: "Beca",
    Linea: "Beca Profesor Alberto Assa",
    link: 'https://www.uninorte.edu.co/web/apoyo-financiero/becas'
  },
  {
    isActive: true,
    Decision: "Beca",
    Linea: "Beca Marvel Moreno",
    link: 'https://www.uninorte.edu.co/web/apoyo-financiero/becas'
  },
  {
    isActive: true,
    Decision: "Beca",
    Linea: "Beca Talento Promigas",
    link: 'https://www.uninorte.edu.co/web/apoyo-financiero/becas'
  },
  {
    isActive: true,
    Decision: "Beca",
    Linea: "Beca Orgullo Caribe",
    link: 'https://www.uninorte.edu.co/web/apoyo-financiero/becas'
  },
  {
    isActive: true,
    Decision: "Beca",
    Linea: "Beca Uninorte Caribe",
    link: 'https://www.uninorte.edu.co/web/apoyo-financiero/becas'
  },
  {
    isActive: true,
    Decision: "Beca",
    Linea: "Beca Roble Amarillo",
    link: 'https://www.uninorte.edu.co/web/apoyo-financiero/becas'
  },
  {
    isActive: true,
    Decision: "Beca",
    Linea: "Beca Excelencia Deportiva",
    link: 'https://www.uninorte.edu.co/web/apoyo-financiero/becas'
  },
  {
    isActive: true,
    Decision: "Beca",
    Linea: "Beca Profesor Alberto Assa",
    link: 'https://www.uninorte.edu.co/web/apoyo-financiero/becas'
  },
  {
    isActive: true,
    Decision: "Beca",
    Linea: "Beca Talento Promigas",
    link: 'https://www.uninorte.edu.co/web/apoyo-financiero/becas'
  },
  {
    isActive: true,
    Decision: "Beca",
    Linea: "Beca Karl C. Parrish Jr.",
    link: 'https://www.uninorte.edu.co/web/apoyo-financiero/becas'
  },
  {
    isActive: true,
    Decision: "Beca",
    Linea: "Beca Gases del Caribe",
    link: 'https://www.uninorte.edu.co/web/apoyo-financiero/becas'
  }
]
